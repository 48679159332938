import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Tooltip, useTheme } from "@mui/material";
import { Link } from "@remix-run/react";

import { PLATFORM_PRIVACY_URL, PLATFORM_TERMS_URL } from "~/constants/platform";
import { Routes } from "~/constants/routes";

import { DiscordIcon } from "./icons/discord-icon";
import { useLaunchIntercom } from "./scripts/intercom-script";

export function Footer() {
  const theme = useTheme();
  const launchIntercom = useLaunchIntercom();

  const footer = {
    "& a": { color: theme.palette.primary.dark, fontWeight: "bold" },
    height: "fit-content",
    padding: "40px 0 60px 0",
    position: "relative",
    marginTop: "auto",
    bottom: "0",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    fontFamily: "Figtree, sans-serif",
    backgroundColor: "rgb(222, 244, 253)",
  };
  const footerSection = {
    display: "flex",
    padding: "30px",
    flexDirection: "column",
    gap: "1em",
  };
  const imageContainer = {
    width: "230px",
    "& img": {
      width: "100%",
    },
  };

  return (
    <Box component="footer" sx={footer}>
      <Box sx={{ ...footerSection }}>
        <Box sx={{ ...imageContainer, ml: -2 }}>
          <img src="/images/logo_transparent_background.png" alt="logo" />
        </Box>

        <Box sx={{ display: "flex", gap: 1 }}>
          <a
            href="https://twitter.com/WishTender"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="WishTender Twitter account"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://instagram.com/wishtender_"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="WishTender Instagram account"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.wishtender.com/discord"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="WishTender Bot"
          >
            <DiscordIcon />
          </a>
        </Box>

        <Box sx={{ display: "flex", gap: 0.5 }}>
          Built By Dashiell Bark-Huss:
          <Tooltip
            title={
              <img
                style={{ borderRadius: "50%" }}
                height="70"
                width="70"
                src="https://pbs.twimg.com/profile_images/1088531842918244352/WVeHmdkb_400x400.jpg"
                alt="dash twitter"
              />
            }
          >
            <a href="https://twitter.com/DashBarkHuss">
              <TwitterIcon />
            </a>
          </Tooltip>
        </Box>

        <Box>&copy; 2021-{new Date().getFullYear()} WishTender LLC</Box>
      </Box>
      <Box sx={footerSection}>
        <h2>Info</h2>
        <Link to={Routes.landing.careers()}>Careers</Link>
        <a target="_blank" href={PLATFORM_TERMS_URL} rel="noreferrer noopener">
          Terms of Service
        </a>
        <a target="_blank" href={PLATFORM_PRIVACY_URL} rel="noreferrer noopener">
          Privacy Policy
        </a>
        <Link to={Routes.landing.how()}>How it works</Link>
        <a href={Routes.external.intercom()}>Frequently Asked Questions</a>
      </Box>
      <Box sx={footerSection}>
        <h2>Contact</h2>

        <div>
          <Box
            component="button"
            onClick={() => launchIntercom()}
            sx={{
              p: 0,
              border: 0,
              background: 0,
              color: "#0185a9",
              fontWeight: 700,
              fontSize: 16,
              cursor: "pointer",
            }}
          >
            Support Chat
          </Box>

          <Box mt={0.5} />

          <a href="mailto:support@wishtender.com" style={{ display: "block", fontWeight: 400 }}>
            Can't see the support chat?
          </a>
        </div>
      </Box>
    </Box>
  );
}
