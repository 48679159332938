import { Box, Toolbar, Typography } from "@mui/material";
import { Link } from "@remix-run/react";

import { Routes } from "~/constants/routes";
import type { Auth } from "~/types";
import { themeV2 } from "~/utils/theme";

import { HeaderAppBar } from "./general-headers/header-app-bar";
import { HeaderLogoDesktop, HeaderLogoMobile } from "./header-logo";

export function SimpleHeader({ auth }: { auth: Auth }) {
  return (
    <header>
      <HeaderAppBar screenSize="mobile">
        <Toolbar>
          <Box>
            <Link to={Routes.landing.home()}>
              <HeaderLogoMobile />
            </Link>
          </Box>
          {auth && auth.user && (
            <Box sx={{ marginLeft: "auto" }}>
              <Link
                color={themeV2.palette.primary.main}
                to={Routes.auth.logout()}
                style={{ fontWeight: 600 }}
              >
                Log Out
              </Link>
            </Box>
          )}
        </Toolbar>
      </HeaderAppBar>
      <HeaderAppBar screenSize="desktop">
        <Toolbar>
          <Box>
            <Link to={Routes.landing.home()}>
              <HeaderLogoMobile />
              <HeaderLogoDesktop />
            </Link>
          </Box>
          <Box ml={"auto"}>
            {auth && auth.user && (
              <>
                <Typography
                  sx={{ display: "inline-block" }}
                  mr={2}
                  color={themeV2.palette.neutral[5]}
                >
                  Logged in as: <span style={{ fontWeight: 600 }}>{auth.user.email}</span>
                </Typography>
                <Link
                  color={themeV2.palette.primary.main}
                  to={Routes.auth.logout()}
                  style={{ fontWeight: 600 }}
                >
                  Log Out
                </Link>
              </>
            )}
          </Box>
        </Toolbar>
      </HeaderAppBar>
    </header>
  );
}
