import { Box } from "@mui/material";

export function HeaderLogoDesktop() {
  return (
    <Box
      component="img"
      src="/images/logo.png"
      alt="WishTender logo"
      sx={{
        maxWidth: "100%",
        position: "relative",
        objectFit: "contain",
        display: {
          xs: "none",
          lg: "block",
        },
        maxHeight: {
          xs: "35px",
          lg: "68px",
        },
      }}
    />
  );
}

export function HeaderLogoMobile() {
  return (
    <Box
      component="img"
      src="/images/icon_logo.png"
      alt="WishTender logo"
      sx={{
        maxHeight: "35px",
        maxWidth: "100%",
        position: "relative",
        display: {
          xs: "block",
          lg: "none",
        },
      }}
    />
  );
}

export function HeaderLogoSmallDesktop() {
  return (
    <Box
      component="img"
      src="/images/icon_logo.png"
      alt="WishTender logo"
      sx={{
        maxHeight: "36px",
        maxWidth: "100%",
        position: "relative",
        display: {
          xs: "none",
          lg: "block",
          xl: "none",
        },
      }}
    />
  );
}
