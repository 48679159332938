import { AppBar } from "@mui/material";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";

type HeaderAppBarProps = {
  screenSize: "desktop" | "mobile" | "tablet";
  children: ReactNode;
};

export function HeaderAppBar({ screenSize, children }: HeaderAppBarProps) {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    if (typeof document === "undefined") return;

    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);

    function listenScrollEvent() {
      if (window.scrollY < 70 && hasScrolled) return setHasScrolled(false);
      if (window.scrollY >= 70 && !hasScrolled) return setHasScrolled(true);
    }
  }, [hasScrolled]);

  return (
    <AppBar
      component="div"
      position="fixed"
      sx={{
        height: "72px",
        display: {
          xs: screenSize === "mobile" ? "grid" : "none",
          sm: screenSize === "tablet" ? "grid" : "none",
          lg: screenSize === "desktop" ? "grid" : "none",
        },
        color: "#000",
        bgcolor: "#fff",
        alignItems: "center",
        boxShadow: hasScrolled
          ? "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;"
          : "none",
        px: {
          lg: 3.75,
          xl: 8,
          xxl: 12,
          md: 3.75,
          sm: 3,
          xs: 2,
        },
      }}
    >
      {children}
    </AppBar>
  );
}
