import { useLocation } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { useEffect, useMemo } from "react";

import { PLATFORM_INTERCOM_APP_ID as APP_ID } from "~/constants/platform";
import { useAuth } from "~/hooks/use-auth";

const BASE_SETTINGS = {
  api_base: "https://api-iam.intercom.io",
  app_id: APP_ID,
};

export const IntercomScript = Sentry.withErrorBoundary(() => {
  const { user, gifter, wisher } = useAuth();
  const location = useLocation();

  const intercomData = useMemo(() => {
    if (typeof window === "undefined") return {};
    if (!user) return {};

    const twitterUrl = wisher?.twitterUsername
      ? `https://twitter.com/${wisher?.twitterUsername}`
      : undefined;
    const stripeAccountUrl = wisher?.stripeAccountId
      ? `https://dashboard.stripe.com/connect/accounts/${wisher?.stripeAccountId}`
      : undefined;
    const stripeSearchUrl = createStripeSearchUrl(user?.email);
    const sendGridUrl = createSendGridSearchUrl(user?.email);

    const apiUrl = window.env.REACT_APP_BASE_URL;

    return {
      email: user.email,
      user_id: user.id,
      gifter_id: gifter?.id,
      alias_id: wisher?.id,
      wishlist_id: wisher?.wishlistId,
      twitter_url: twitterUrl,
      wishlist_url: `${apiUrl}/api/redirect/users/${user.id}/wishlist`,
      gifter_url: `${apiUrl}/api/redirect/users/${user.id}/gifter`,
      admin_user_url: `${window.location.origin}/admin/users/${user.id}`,
      sendgrid_url: sendGridUrl,
      stripe_url: stripeAccountUrl,
      stripe_purchases_url: stripeSearchUrl,
    };
  }, [gifter, user, wisher]);

  useEffect(() => {
    if (window.env.NODE_ENV !== "production") return;
    if (!window.Intercom) return;
    window.intercomSettings = {
      ...BASE_SETTINGS,
      ...intercomData,
    };

    if (user) {
      window.Intercom("update");
    } else {
      window.Intercom("shutdown");
      window.Intercom("boot", window.intercomSettings);
    }
  }, [intercomData, user, location]);

  if (process.env.NODE_ENV !== "production") return <></>;

  /*
  This is z-index system of MUI (https://mui.com/material-ui/customization/z-index/):
  - mobile stepper: 1000
  - fab: 1050
  - speed dial: 1050
  - app bar: 1100
  - drawer: 1200
  - modal: 1300
  - snackbar: 1400
  - tooltip: 1500
  If you just make the drawer index = intercom index + 1 then the drawer will be on top of all. We cannot see any tooltip, modal or snackbar when the drawer is opened.
  So I have to make the intercom index down to the same as the fab instead of increasing all the rest
 */
  /*
    The div with class "intercom-dfosxs" is the Intercom FAB
    It's a dynamic class but seems never change, Intercom doesn't have any other selector for this FAB
  */
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .intercom-lightweight-app, .intercom-namespace .intercom-app div {
            z-index: 1050!important;
          }
          .intercom-namespace .intercom-app > div.intercom-dfosxs {
            z-index: 1051!important;
          }
          .intercom-namespace .intercom-app .intercom-messenger-frame {
            z-index: 1151!important; /* Message container should be higher than the app bar and FAB */
          }
          .intercom-lightweight-app-gradient {
            z-index: 1051!important;
          }
          .intercom-lightweight-app-launcher {
            z-index: 1052!important;
          }
          @media only screen and (max-width: 600px) {
            .intercom-namespace .intercom-app > div.intercom-dfosxs, .intercom-lightweight-app-launcher {
              bottom: 76px !important;
            }
            .intercom-namespace .intercom-app .intercom-messenger-frame {
              bottom: 148px !important;
            }
          }
          `,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
              window.intercomSettings = {
                api_base: "https://api-iam.intercom.io",
                app_id: "${APP_ID}",
              };
            `,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
              (function () {
                const path = window.location.pathname.slice(0, 8);
                if (path == "/stream/") return;
                var w = window;
                var ic = w.Intercom;
                if (typeof ic === "function") {
                  ic("reattach_activator");
                  ic("update", w.intercomSettings);
                } else {
                  var d = document;
                  var i = function () {
                    i.c(arguments);
                  };
                  i.q = [];
                  i.c = function (args) {
                    i.q.push(args);
                  };
                  w.Intercom = i;
                  var l = function () {
                    var s = d.createElement("script");
                    s.type = "text/javascript";
                    s.async = true;
                    s.src = "https://widget.intercom.io/widget/${APP_ID}";
                    var x = d.getElementsByTagName("script")[0];
                    x.parentNode.insertBefore(s, x);
                  };
                  if (document.readyState === "complete") {
                    l();
                  } else if (w.attachEvent) {
                    w.attachEvent("onload", l);
                  } else {
                    w.addEventListener("load", l, false);
                  }
                }
              })();
              `,
        }}
      />
    </>
  );
}, {});

declare global {
  interface Window {
    Intercom?: any;
    intercomSettings?: any;
  }
}

function createStripeSearchUrl(email?: string) {
  if (!email) return;
  const searchParams = new URLSearchParams();
  searchParams.set("query", email);
  return `https://dashboard.stripe.com/search?${searchParams.toString()}`;
}

function createSendGridSearchUrl(email?: string) {
  if (!email) return;
  const searchParams = new URLSearchParams();
  searchParams.set(
    "filters",
    JSON.stringify([
      {
        val: [email],
        selectedFieldName: "to_email",
        comparisonType: "Contains",
      },
    ]),
  );
  searchParams.set("page", "1");
  return `https://app.sendgrid.com/email_activity?${searchParams.toString()}`;
}

export function useLaunchIntercom() {
  return () => {
    window?.Intercom?.("show");
  };
}
